<template>
    <div class="dark:bg-gray-900 bg-white dark:text-white text-gray-700 py-24">
        <div class="lg:container mx-auto px-5 ">

            <div class="flex sm:flex-col-reverse md:flex-row">
                <div class="sm:w-full md:w-3/4 sm:mb-5 md:mb-0">
                    <h1 class="text-3xl mb-5 ">Заявка на приобретение программного продукта РУП «Белтаможсервис»</h1>
                    <p>
                        РУП "Белтаможсервис" разрабатывает собственные программные продукты и комплексы, осуществляет
                        реализацию и сопровождение программных продуктов в сфере таможенного дела, а также постоянное
                        обновление программного обеспечения и техническую поддержку.
                    </p>
                </div>

                <div class="sm:w-full md:w-1/4 image-wrapper">
                    <img v-show="dark" src="@/assets/img/iso/soft-dark.svg" alt="software">
                    <img v-show="!dark" src="@/assets/img/iso/soft.svg" alt="software">
                </div>
            </div>


            <select class="block" v-model="form.product.name" @change="dropOptions">
                <option value="" selected disabled>Выберите программный продукт</option>
                <option :value="prod.value" v-for="(prod,  idx) in products" :key="'product_'+idx">{{
                        prod.name
                    }}
                </option>
            </select>
            <small class="text-red-600"
                   v-if="!$v.form.product.name.required && $v.form.product.name.$dirty">{{
                    $t('forms.validation.required')
                }}</small>


            <div class="services-wrapper"
                 v-if="form.product.name.length && productServices.length">
                <div class="p-5">
                    <label class="block" v-for="(service,idx) in productServices" :key="'service-'+idx">
                        <input type="checkbox" v-model="form.product.services" :value="service.name"
                              >
                        {{ service.name }}
                    </label>
                </div>


            </div>
            <div>
                <h2>Информация об организации</h2>
                <div class="block-wrapper">
                    <div>
                        <input v-model="form.organization.name" type="text"
                               placeholder="Укажите полное наименование организации *">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.name.required && $v.form.organization.name.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.organization.unp" type="text" placeholder="УНП">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.unp.required && $v.form.organization.unp.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.organization.okpo" type="text" placeholder="ОКПО">
                    </div>
                    <div>
                        <input v-model="form.organization.address" type="text" placeholder="Юридический адрес *">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.address.required && $v.form.organization.address.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.organization.mailAddress" type="text"
                               placeholder="Адрес для корреспонденции *">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.mailAddress.required && $v.form.organization.mailAddress.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.organization.phone" type="text" placeholder="Номер телефона *">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.phone.required && $v.form.organization.phone.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.organization.email" type="text" placeholder="Электронная почта *">
                        <small class="text-red-600"
                               v-if="!$v.form.organization.email.required && $v.form.organization.email.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                        <small class="text-red-600"
                               v-if="!$v.form.organization.email.email && $v.form.organization.email.$dirty">{{
                                $t('forms.validation.email')
                            }}</small>
                    </div>

                </div>
            </div>

            <div>
                <h2>Банковские реквизиты</h2>
                <div class="block-wrapper">
                    <div>
                        <input v-model="form.bank.name" type="text" placeholder="Укажите полное наименование банка *">
                        <small class="text-red-600"
                               v-if="!$v.form.bank.name.required && $v.form.bank.name.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.bank.address" type="text" placeholder="Укажите адрес банка *">
                        <small class="text-red-600"
                               v-if="!$v.form.bank.address.required && $v.form.bank.address.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.bank.account" type="text" placeholder="Р/с *">
                        <small class="text-red-600"
                               v-if="!$v.form.bank.account.required && $v.form.bank.account.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>
                    <div>
                        <input v-model="form.bank.mfo" type="text" placeholder="Код МФО *">
                        <small class="text-red-600"
                               v-if="!$v.form.bank.mfo.required && $v.form.bank.mfo.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>


                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div class="">
                    <h2>Уполномоченное лицо</h2>
                    <div class="block-wrapper-full">
                        <div>
                            <input v-model="form.upoln.name" type="text" placeholder="ФИО">
                            <small class="text-red-600"
                                   v-if="!$v.form.upoln.name.required && $v.form.upoln.name.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                        </div>
                        <div>
                            <input v-model="form.upoln.position" type="text" placeholder="Должность">
                            <small class="text-red-600"
                                   v-if="!$v.form.upoln.position.required && $v.form.upoln.position.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                        </div>
                        <div>
                            <input v-model="form.upoln.document" type="text" placeholder="Действует на основании">
                            <small class="text-red-600"
                                   v-if="!$v.form.upoln.document.required && $v.form.upoln.document.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                        </div>

                    </div>
                </div>
                <div class="">
                    <h2>Контактное лицо</h2>
                    <div class="block-wrapper-full">
                        <div>
                            <input v-model="form.contact.name" type="text" placeholder="ФИО">
                            <small class="text-red-600"
                                   v-if="!$v.form.contact.name.required && $v.form.contact.name.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                        </div>
                        <div>
                            <input v-model="form.contact.email" type="text" placeholder="Email">
                            <small class="text-red-600"
                                   v-if="!$v.form.contact.email.required && $v.form.contact.email.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                            <small class="text-red-600"
                                   v-if="!$v.form.contact.email.email && $v.form.contact.email.$dirty">{{
                                    $t('forms.validation.email')
                                }}</small>
                        </div>
                        <div>
                            <input v-model="form.contact.phone" type="text" placeholder="Телефон">
                            <small class="text-red-600"
                                   v-if="!$v.form.contact.phone.required && $v.form.contact.phone.$dirty">{{
                                    $t('forms.validation.required')
                                }}</small>
                        </div>

                    </div>
                </div>
            </div>


            <div class="my-5">
                <div class="file-wrapper">

                    <label class="">Свидетельство о государственной регистрации</label>
                    <div>
                        <input type="file" ref="registration" @change="setFile($event, 'registration')">
                        <small class="text-red-600"
                               v-if="!$v.form.file.registration.required && $v.form.file.registration.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>

                </div>
                <div class="file-wrapper">
                    <label class="">Копия устава</label>
                    <div>
                        <input type="file" ref="regulation" @change="setFile($event, 'regulation')">
                        <small class="text-red-600"
                               v-if="!$v.form.file.regulation.required && $v.form.file.regulation.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>

                </div>
                <div class="file-wrapper">
                    <label class=" ">Копия документа, подтверждающего полномочия лица, которое будет подписывать
                        договор</label>
                    <div>
                        <input type="file" ref="credentials" @change="setFile($event, 'credentials')">
                        <small class="text-red-600"
                               v-if="!$v.form.file.credentials.required && $v.form.file.credentials.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </div>

                </div>


            </div>

            <div class="my-5">
                <label class=" block"><input v-model="form.agree" class="mr-2" type="checkbox">Согласен с политикой
                    конфиденциальности</label>
                <small class="text-red-600"
                       v-if="!$v.form.agree.sameAsTrue && $v.form.agree.$dirty">{{
                        $t('forms.validation.required')
                    }}</small>
            </div>

            <button class=" inline-block px-5 py-3 mr-2 rounded bg-green-400" @click="submit">Отправить</button>
            <button class=" inline-block px-5 py-3 rounded bg-blue-400" @click="clear">Очистить</button>
        </div>
    </div>

</template>

<script>
import {validationMixin} from "vuelidate";
import {required, email} from "vuelidate/lib/validators";
import axios from "axios";

export default {
    name: "SoftwareForm",
    meta: {
        title: 'Заявка на приобритение ПО'
    },
    computed: {
        dark() {
            return this.$store.getters['darkTheme']
        },
      productServices(){
        if(['declarantSD','declarantEPI', 'declarantTD', 'declarantSPO'].includes(this.form.product.name)){
          return this.services.declarant
        }else {
          return this.services[this.form.product.name] || []
        }
      }
    },
    data: () => ({
        products: [
            {name: '1.1 АРМ "ПТО"', value: 'pto'},
            {name: '1.2 АРМ "БТС: СПЕЦИАЛИСТ"', value: 'specialist'},
            {name: '1.3 АС "СЭЗ"', value: 'sez'},
            {name: '1.4 ПМ "ТАМОЖЕННЫЙ ПЕРЕВОЗЧИК"', value: 'carrier'},
            {name: '1.5 КПП "е-Декларант" (передача СД)', value: 'declarantSD'},
            {name: '1.6 КПП "е-Декларант" (передача ЭПИ)', value: 'declarantEPI'},
            {name: '1.7 КПП "е-Декларант" (передача ТД)', value: 'declarantTD'},
            {name: '1.8 КПП "е-Декларант" (передача Сертификатов)', value: 'declarantSPO'},
            {name: '2 КПП "е-Декларант" (передача СД, ЭПИ, ТД, ДТ, ДТС, КДТ, Сертификатов)', value: 'declarantFull'},
        ],
        services: {
            specialist: [
                {name: '1.3.1 Уведомление о размещении ТС в ЗТК (на основании ЭПИ)'},
                {name: '1.3.2 Учет пропуска ТС и физлиц через КПП'},
                {name: '1.3.3 Просмотр ЭПИ'},
                {name: '1.3.4 Картотека СОП'},
            ],
            declarant: [
                {name: "1. Контроль на объект интеллектуальной собственности"},
                {name: "2. Выгрузка данных по таможенным документам"},
                {name: "3. Детализация деклараций в разрезе пользователей"}
            ],
            declarantFull: [
                {name: '1. Загрузка файла *.csv в ЗВТ'},
                {name: '2. Загрузка файла *.csv в журнал ДТЭГ/ПТДЭГ'},
                {name: '3. Конвертация ЗВТ в ДТ (журнал ИМ 40 ЭД)'},
                {name: '4. Конвертация ДТ (журнал импорт 40 ЭД) в ЗВТ'},
                {name: '5. Конвертация ЭПИ в сертификат обеспечения уплаты'},
                {name: '6. Контроль на объект интеллектуальной собственности'},
                {name: '7. Конвертация транзитной декларации в ЭПИ авто'},
                {name: '8. Выгрузка данных по таможенным документам'},
                {name: '9. Выгрузка данных по таможенным документам'},
                {name: '10. Выгрузка данных по таможенным документам'},
            ]
        },
        form: {
            product: {
                name: '',
                equipment: '',
                services: [],
            },
            organization: {
                name: '',
                unp: '',
                okpo: '',
                address: '',
                mailAddress: '',
                phone: '',
                email: ''
            },
            bank: {
                name: '',
                address: '',
                account: '',
                mfo: '',
            },
            upoln: {
                name: '',
                position: '',
                document: '',
            },
            contact: {
                name: '',
                email: '',
                phone: ''
            },
            file: {
                registration: {},
                regulation: {},
                credentials: {}
            },
            agree: false
        }
    }),

    mixins: [validationMixin],
    validations: {
        form: {
            product: {
                name: {required},
            },
            organization: {
                name: {required},
                unp: {required},
                address: {required},
                mailAddress: {required},
                phone: {required},
                email: {required, email}
            },
            bank: {
                name: {required},
                address: {required},
                account: {required},
                mfo: {required},
            },
            upoln: {
                name: {required},
                position: {required},
                document: {required},
            },
            contact: {
                name: {required},
                email: {required, email},
                phone: {required}
            },
            file: {
                registration: {required},
                regulation: {required},
                credentials: {required}
            },
            agree: {
                sameAsTrue: value => value === true
            }
        }
    },
    methods: {
        setFile(event, type) {
            let file = event.target.files[0]
            if (file.size > 10485760) {
                this.$refs[type].value = ''
                return this.$notify({
                    title: 'Ошибка',
                    text: 'Файл превышает 10 МБ',
                    type: 'error'
                })
            }
            this.form.file[type] = file

        },
        dropOptions() {
            this.form.product.equipment = ''
            this.form.product.services = []
        },
        submit() {

            this.$v.$touch()
            if (!this.$v.$invalid) {
                let payload = JSON.parse(JSON.stringify(this.form))

                payload.product.name = this.products.find(prod => prod.value === payload.product.name)['name']
                payload.organization.email = btoa(payload.organization.email)
                payload.contact.email = btoa(payload.contact.email)
                let data = new FormData()

                data.append('form', JSON.stringify(payload))
                data.append('registration', this.form.file.registration)
                data.append('regulation', this.form.file.regulation)
                data.append('credentials', this.form.file.credentials)


                axios.post('https://declarant.by/rest/forms/software', data).then(res => {
                    this.$notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    this.clear()
                }).catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })


            }
        },
        clear() {
            this.form = {
                product: {
                    name: '',
                        equipment: '',
                        services: [],
                },
                organization: {
                    name: '',
                        unp: '',
                        okpo: '',
                        address: '',
                        mailAddress: '',
                        phone: '',
                        email: ''
                },
                bank: {
                    name: '',
                        address: '',
                        account: '',
                        mfo: '',
                },
                upoln: {
                    name: '',
                        position: '',
                        document: '',
                },
                contact: {
                    name: '',
                        email: '',
                        phone: ''
                },
                file: {
                    registration: {},
                    regulation: {},
                    credentials: {}
                },
            }
            this.agree = false
            this.$v.$reset()
        },
    }
}
</script>

<style scoped>
input[type=checkbox] {
    transform: scale(1.2);
    @apply my-2;
}

.info {
    @apply p-3  bg-blue-900  dark:border-transparent  text-white
    block w-full rounded outline-none mb-2;
}

.dark .info {
    background-color: #379199;
}

.services-wrapper {
    @apply rounded dark:bg-gray-800 bg-white
}

.block-wrapper {
    @apply grid sm:grid-cols-1 sm:gap-y-2 lg:grid-cols-2  lg:gap-2;
}

.block-wrapper-full {
    @apply grid grid-cols-1 gap-2 ;
}

.file-wrapper {
    @apply flex sm:flex-col lg:flex-row;
}

.file-wrapper div {
    @apply sm:w-full lg:w-1/2
}

.image-wrapper {
    @apply flex sm:justify-center md:justify-end;
}

.image-wrapper img {
    @apply max-h-52  ;
}

.file-wrapper label {
    @apply sm:w-full lg:w-1/2;
}

input[type=text]:nth-child(even) {
    @apply mr-1;
}

input[type=text] {
    @apply p-3 dark:bg-gray-800 bg-white border w-full dark:border-transparent border-gray-400 dark:text-white
    text-black w-full rounded outline-none ;
}

select {
    @apply overflow-hidden mb-2 w-full rounded p-3 outline-none dark:bg-gray-800 bg-white border border-gray-400 dark:border-transparent;
}

h2 {
    @apply text-2xl my-5;
}

input[type=file] {
    @apply mb-2 block w-full  cursor-pointer dark:bg-gray-800 bg-white dark:text-white text-gray-900
    outline-none border dark:border-transparent border-gray-400 text-sm rounded;
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply text-white dark:bg-gray-800 bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-3 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

</style>